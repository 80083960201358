import React, { Component } from 'react';
import AuthenticatorContext from './authenticatorcontext'
import {withRouter} from "react-router-dom";
import {withApplicationState} from "react-application-state";
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import applicationConfig from './../../../config/application';

class Authenticate extends Component {

    static propTypes = {
        appState: PropTypes.object,
    }

    static defaultProps = {
        appState: {
            state: {},
            actions: {}
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: '',
            userId: ''
        };
    }

    static validateToken = (token) => {
        try {
            const decoded = jwtDecode(token);
            console.log('validateToken', decoded)
            if(decoded.aud === applicationConfig.JWT.allowedAudience && decoded.iss === applicationConfig.JWT.allowedIssuer)
                console.log('decoded', decoded);
                return decoded;
        }catch(_){
             console.log('validateToken failed', _)
        }
        return false;
    }

    static getDerivedStateFromProps = (nextProps, oldState) => {
        console.log('authenticator getDerivedStateFromProps', nextProps, oldState)
        const tokenInfo = Authenticate.validateToken(nextProps.appState.state.jwtToken);
        // console.log('getDerivedStateFromProps', nextProps, oldState, tokenInfo);
        let newState = {
            isAuthenticated: false,
            code: ''
        };
        if(tokenInfo)
            newState = {
                isAuthenticated: true,
                code: tokenInfo.code
            };
        if(oldState.code !== newState.code)
            nextProps.appState.actions.setCode(newState.code);
        return newState;

    }

    render() {
        return (
            <AuthenticatorContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticatorContext.Provider>
        )
    }
}

export const Authenticator = withApplicationState(withRouter(Authenticate));
