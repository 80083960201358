import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/ktoanswer.scss";
import {TextareaItem} from "antd-mobile";
import Rate from '../../rate';

class KtoAnswer extends Component {
    saveAnswer = (value, syncToServer = true) => {
        this.props.onChange(value, syncToServer);
    };

    renderType = () => {
        const question= this.props.question;

        if (question.Type === 'rating') {
            return (
                <div>
                    <Rate onChange={this.saveAnswer} value={question.answer}/>
                </div>
            )
        } else {
            return (
                <div>
                    <TextareaItem rows={4} onBlur={(value) => {
                        this.saveAnswer(value);
                    }} onChange={(value) => {
                        this.saveAnswer(value, false);
                    }} value={question.answer} />
                </div>
            )
        }
    };

    render() {
        return (
            <div className="KtoAnswer">
                <div className="answerOption">
                    <label className="radioCustomLabel">
                        {this.renderType()}
                    </label>
                </div>
            </div>
        )
    }
}

export default withRouter(KtoAnswer);
