import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/login.scss";
import {Button, InputItem} from "antd-mobile";
import {withApplicationState} from "react-application-state";
import {post} from "../../../lib/api";

class Login extends Component {

    state = {
        redirect: false
    };


    componentDidMount = () => {
        const path = window.location.pathname.split('/');
        if(path.length >= 2){
            this.getToken(path[1]);
        }
    }

    getToken = async (code) => {
        if(!code)
            return;
        const result = await post(`/authenticate`, { code }, null, () => {});
        if(result && result.token)
            this.props.appState.actions.setJwtToken(result.token);
            this.props.history.push(`${code}`);
    }


    render() {
        return (
            <div className="Login">
                <div className="content-container">
                    <div className='KtoQuestions'>
                        <div className="logo"></div>
                        <div className="styled-block">
                            <p className="text">Voer hieronder de code in die je ontvangen hebt in de email</p>
                            <InputItem value={this.state.code} onChange={(val) => this.setState({code: val})} >Code</InputItem>
                            <Button inline onClick={() => this.getToken(this.state.code)}>Verzend</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApplicationState(withRouter(Login));
