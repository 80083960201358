export default {
    JWT: {
        allowedAudience: 'so-kto',
        allowedIssuer: 'so-kto',
        tokenExpiredMessage: 'Je sessie is verlopen! Helaas betekend dat dat je opnieuw moet inloggen.'
    },
    theme: {
        colors: {
            primary: '#ffeb70',
            secondary: '#7bebcf',
            tertiary: '#252166'
        }
    },
    api: {
        uri: 'https://kto-api.slimopgewekt.app/kto'
    }
}
