import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/ktoquiz.scss";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import KtoQuestionCount from "../../ktoquestioncount";
import KtoAnswer from "../../ktoanswer";
import {Button} from "antd-mobile";

class KtoQuiz extends Component {
    static propTypes = {
        questions: PropTypes.array.isRequired,
        index: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            questions: props.questions
        }
    }

    render() {
        const {index} = this.props;
        const {questions} = this.state;
        console.log('KtoQuiz', index, questions)
        if(questions[index]){
            return (
                <div className="KtoQuiz">
                    <ReactCSSTransitionGroup
                        className="quizQuestions"
                        transitionName="transition"
                        transitionEnterTimeout={250}
                        transitionLeaveTimeout={250}
                    >
                        <div key={index} className="styled-block">
                            <KtoQuestionCount
                                counter={index}
                                total={questions.length}
                            />
                            <div>
                                <h2 className="question-text">{questions[index].Text}</h2>
                            </div>
                            <KtoAnswer
                                question={questions[index]}
                                onChange={(value, sync) => {
                                    const questions = [...this.state.questions];
                                    questions[index].answer = value;
                                    this.setState({
                                        questions: questions,
                                    })
                                    this.props.onChange(index, value, 0, sync);
                                }}
                            />

                            {index > 0 ?
                                <Button inline className='Buttons' style={{marginRight: '10px'}}
                                        onClick={() => {
                                            this.props.onChange(index, questions[index].answer, -1);
                                        }}>Vorige</Button>
                                :
                                ''
                            }

                                <Button inline className='Buttons'
                                        onClick={() => {
                                            this.props.onChange(index, questions[index].answer, 1);
                                        }}>{index === questions.length - 1 ? 'Afronden' : 'Volgende'}</Button>
                        </div>
                    </ReactCSSTransitionGroup>
                </div>
            )
        }else{
            return '';
        }
    }
}

export default withRouter(KtoQuiz);
