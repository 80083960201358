const setJwtToken = (token) => () => {
    return {jwtToken: token}
};
const setBrand = (brand) => () => {
    return {brand: brand}
};
const setCode = (code) => () => {
    return {code: code}
};



module.exports = {
    setBrand,
    setJwtToken,
    setCode
}
