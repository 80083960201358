import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/rate.scss";
import {faStar as regularStar} from "@fortawesome/free-regular-svg-icons";
import {faStar as solidStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Rate extends Component {

    limitValue = (value) => {
        return value > 5 ? 5 : value < 0 ? 0 : value;
    }

    renderStars = (value) => {
        let count = 0;
        let stars = [];
        while(count < value) {
            stars.push(this.renderStar(count + 1, true));
            count++;
        }
        while(count < 5) {
            stars.push(this.renderStar(count + 1, false));
            count++;
        }
        return stars;
    }

    renderStar = (position, active) => {
        return <div className="rate-star" key={position}>
            <FontAwesomeIcon
                icon={active ? solidStar : regularStar}
                onClick={() => this.props.onChange(position)}
            />
        </div>
    }

    render() {
        const {value} = this.props;
        const limitedValue = this.limitValue(value);
        return (
            <div className="Rate">
                {
                    this.renderStars(limitedValue)
                }
            </div>
        )
    }
}

export default withRouter(Rate);
