import React, { Component } from 'react';
import '../assets/app.scss';
import Layout from "./layout";
import Router from "../../router";
import "antd-mobile/dist/antd-mobile.css";
import {BrowserRouter} from "react-router-dom";
import {StateProvider} from "react-application-state";
import {Authenticator, WhenAuthenticated, WhenNotAuthenticated} from "../../authenticator";
import Login from "../../login";
const {actions} = require('./../../../actions');

export default class App extends Component {
  render() {
    return (
        <StateProvider actions={actions} initial={{brand: 'so', jwtToken: ''}} options={{syncLocalStorage: false}}>
            <div className="App">
                <BrowserRouter>
                        <Authenticator>
                            <WhenAuthenticated>
                                <Layout>
                                    <Router/>
                                </Layout>
                            </WhenAuthenticated>
                            <WhenNotAuthenticated>
                                <Login />
                            </WhenNotAuthenticated>
                        </Authenticator>
                </BrowserRouter>
            </div>
        </StateProvider>
    );
  }
}

