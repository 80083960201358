import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {withApplicationState} from "react-application-state";

class Layout extends Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }),
        history: PropTypes.object,
        appState: PropTypes.object
    };

    render() {
        return (
            <div className={`Layout ${this.props.appState.state.brand}`}>
                <div className={`content-container`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default withApplicationState(withRouter(Layout));
