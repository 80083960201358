import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {Button, Toast} from "antd-mobile";
import KtoQuiz from "../../ktoquiz";
import {withApplicationState} from "react-application-state";
import {get, post} from "../../../lib/api";

import "../assets/ktoquestions.scss";

class KtoEnquete extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            loading: true,
            counter: -1,
            enqueteId: props.match.params.code,
            questions: [],
            done: false,
            welcome: '',
            thanks: '',
            questionaryId: null,
            brand: 'so',
            answers: [],

        };
    }

    convertToBrand = (type) => {
        switch(type){
            case 'Sportvereniging':
                return 'spo';
            case 'Primair Onderwijs':
            case 'Voortgezet Onderwijs':
            case 'Primair & Voortgezet Onderwijs':
                return 'sos';
            case 'Zorginstelling':
                return 'ozio';
            default:
                return 'so';
        }
    };

    componentWillMount = async () => {
        Toast.loading('De enquête wordt geladen');

        let questionaryId = null;

        const json = await get(`/../questionnaire/${this.state.enqueteId}`, this.props.appState.state.jwtToken);
        if(json){
            questionaryId = json.response.QuestionaryId;
            const brand = this.convertToBrand(json.response.Type);
            this.setState({
                questionaryId: questionaryId,
                brand: brand,
                welcome: json.response.Welcome,
                thanks: json.response.Thanks,
                id: json.response.Id
            });
            this.props.appState.actions.setBrand(brand)
        }

        let data = null;
        if(!questionaryId){
            this.setState({
                loading: false
            })
            Toast.hide();
            return;
        } else{
            const jsonSecond = await get(`/questionaries/${questionaryId}/questions`, this.props.appState.state.jwtToken);
            if (jsonSecond) {
                data = jsonSecond.response;
                this.setState({
                    loading: false,
                    questions: data,
                })
                setTimeout(() => {
                    Toast.hide();
                }, 1000);
            }
        }

        const jsonFourth = await get(`/questionaries/${questionaryId}/answers`, this.props.appState.state.jwtToken);
        if(jsonFourth){
            this.setState({
                answers: jsonFourth.response,
            })
        }

    };

    renderLogo = () => {
        return <div className="logo"></div>
    };

    syncAnswer = async (index, answer, type, questiondId) => {
        const {id} = this.state;

        post(`/answers`, {
                Type: type,
                Value: answer,
                QuestionId: questiondId,
                ContactQuestionaryCombinationId: id,
            }, this.props.appState.state.jwtToken)
            .then(response => console.log('Success:', JSON.stringify(response)))
            .catch(error => console.error('Error:', error))
    };

    renderContent = () => {
        const {loading, questions, counter, welcome, thanks, questionaryId, answers} = this.state;

        questions.sort((a, b) => a.Sequence - b.Sequence);

        console.log(answers, questions)
        const equals = answers.length >= questions.length;

        if(loading){
            return(
                <div className='KtoQuestions'>
                </div>
            )
        } else if(!loading && !questionaryId){
            return(
                <div className='KtoQuestions'>
                    {this.renderLogo()}
                    <div className="styled-block">
                        <p className="text">Er is geen enquête gevonden met de opgegeven code</p>
                    </div>
                </div>
            )
        } else if(!loading && equals === true){
            return(
                <div className='KtoQuestions'>
                    {this.renderLogo()}
                    <div className="styled-block">
                        <p className="text">De enquête is al een keer ingevuld!</p>
                    </div>
                </div>
            )
        } else if (!loading && counter === questions.length){
            return (
                <div className='KtoQuestions'>
                    {this.renderLogo()}
                    <div className="styled-block">
                        <p className="text" dangerouslySetInnerHTML={{__html: thanks}}></p>
                        <p>Je kunt nu dit tabblad sluiten</p>
                    </div>
                </div>
            )
        } else if(!loading && counter === -1){
            return(
                <div className='KtoQuestions'>
                    {this.renderLogo()}
                    <div className="styled-block">
                        <p className="text" style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: welcome}}></p>
                        <div className="button-container">
                            <Button inline onClick={() => {
                                this.setState({counter: 0})
                            }}>Start</Button>
                        </div>
                    </div>
                </div>
            )
        } else if(!loading && counter >= 0 && counter < questions.length){
            return(
                <div className='KtoQuestions'>
                    {this.renderLogo()}
                    <KtoQuiz
                        questions={questions}
                        index={counter}
                        onChange={(index, answer, move = 0, sync = false) => {
                            const questions = [...this.state.questions];
                            questions[index].answer = answer;
                            console.log('onChange', questions, index, answer, move)
                            this.setState({
                                questions: questions,
                                counter: index + move
                            })
                            if(sync){
                                this.syncAnswer(index, answer, questions[index].Type, questions[index].Id);
                            }
                        }}
                        enqueteId={questionaryId}
                    />
                </div>
            )
        }
    };

    render() {
        const {brand} = this.state;

        return (
            <div className={`KtoQuestions ${brand}`}>
                <div className='stylingEnquete'>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

export default withApplicationState(withRouter(KtoEnquete));
