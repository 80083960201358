import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import KtoEnquete from "../../ktoenquete";

class Router extends Component {
    render() {
        return <Fragment>
            <Switch>
                <Route path="/:code" exact component={KtoEnquete} />
            </Switch>
        </Fragment>;
    }
}

export default withRouter(Router);
