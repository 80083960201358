import config from '../config/application';

export const get = async (path, token, onError = () => {}) => {
    try {
        const request = await fetch(`${config.api.uri}${path}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });
        const result = await request.json();
        if(result.error && result.status && result.status === 401 && onError)
            onError(result);
        return result;
    } catch(error) {
        onError(error);
        return null;
    }
};

export const post = async (path, content, token, onError) => {
    try {
        const request = await fetch(`${config.api.uri}${path}`,{
        method: 'POST',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(content)
        });
        const result = await request.json();
        if(result.error && result.status && result.status === 401 && onError)
            onError(result);
        return result;
    } catch(error) {
        onError(error);
        return null;
    }
}
