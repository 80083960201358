import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/ktoquestioncount.scss";
import {Progress} from "antd-mobile";
import PropTypes from 'prop-types';

class KtoQuestionCount extends Component {
    static propTypes = {
        counter: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired
    };

    constructor(props){
        super(props);
        this.state = {
            percent: '',
        };
    }

    componentWillMount = () => {
        const bar = this.props.counter / this.props.total * 100;
        this.setState({
            percent: bar,
        });
    };

    render() {
        return (
            <div className="KtoQuestionCount">
                <div className="questionCount">
                    <Progress percent={this.state.percent} showInfo={false}/>
                </div>
            </div>
        )
    }
}

export default withRouter(KtoQuestionCount);
